<template>
  <b-card align="center" class="shadow-sm cursor-pointer" style="height: 200px;">
    <b-img
      height="90"
      src="@/assets/images/services/buliding.svg"
    />
    <h3 class="text-center mt-1">{{ item.title }}</h3>
  </b-card>
</template>
<script>
import { BCard, BImg } from "bootstrap-vue";
export default {
  props:{
    item:{
      type:Object
    },
  },
  components: {
    BCard,
    BImg,
  },
};
</script>